import React, { useEffect, useImperativeHandle, useState } from 'react';

import { SNACKBAR_HIDE_TIME } from '@/components/ui/Snackbar/Snackbar.constants';
import {
  SnackbarRef,
  SnackbarType
} from '@/components/ui/Snackbar/Snackbar.types';
import { useMediaQuery } from '@/hooks/useMediaQuery';

import * as S from './Snackbar.styles';

export const Snackbar = React.forwardRef<SnackbarRef>((_, snackbarRef) => {
  const [mounted, setMounted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState<SnackbarType>('error');
  const isMobile = useMediaQuery('mobile');

  useImperativeHandle(
    snackbarRef,
    () => ({
      open: (m, t) => {
        setIsOpen(true);
        setMessage(m);
        setType(t);
      }
    }),
    []
  );

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setIsOpen(false);
      }, SNACKBAR_HIDE_TIME);
    }
  }, [isOpen]);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <S.SnackbarWrapper
      data-cy={`snackbar-${type}`}
      $type={type}
      $isOpen={isOpen}
    >
      <S.Title variant={isMobile ? 'body4' : 'title4'}>{message}</S.Title>
    </S.SnackbarWrapper>
  );
});

Snackbar.displayName = 'Snackbar';
