import { createElement } from 'react';
import styled, { css } from 'styled-components';

import { HEADING_VARIANTS, P_VARIANTS } from './Typography.constants';
import { TypographyProps, TypographyVariant } from './Typography.types';

function getVariant(variant: TypographyVariant) {
  switch (variant) {
    case 'h0':
      return css`
        font-size: 30px;
        font-weight: 700;
      `;
    case 'h1':
      return css`
        font-size: 24px;
        font-weight: 700;
      `;
    case 'h2':
      return css`
        font-size: 22px;
        font-weight: 700;
      `;
    case 'h3':
      return css`
        font-size: 20px;
        font-weight: 700;
      `;
    case 'h4':
      return css`
        font-size: 18px;
        font-weight: 700;
      `;
    case 'h5':
      return css`
        font-size: 16px;
        font-weight: 700;
      `;
    case 'title1':
      return css`
        font-size: 20px;
        font-weight: 500;
      `;
    case 'title2':
      return css`
        font-size: 18px;
        font-weight: 500;
      `;
    case 'title3':
      return css`
        font-size: 16px;
        font-weight: 500;
      `;
    case 'title4':
      return css`
        font-size: 15px;
        font-weight: 500;
      `;
    case 'title5':
      return css`
        font-size: 14px;
        font-weight: 500;
      `;
    case 'body1':
      return css`
        font-size: 16px;
        font-weight: 400;
      `;
    case 'body2':
      return css`
        font-size: 15px;
        font-weight: 400;
      `;
    case 'body3':
      return css`
        font-size: 14px;
        font-weight: 400;
      `;
    case 'body4':
      return css`
        font-size: 13px;
        font-weight: 400;
      `;
    case 'body5':
      return css`
        font-size: 12px;
        font-weight: 400;
      `;
    case 'link_12':
      return css`
        font-size: 12px;
        font-weight: 400;
        text-decoration: underline;
        transition: color 0.3s ease;

        &:hover {
          color: var(--custom-yellow);
        }
      `;
    case 'link_14':
      return css`
        font-size: 14px;
        font-weight: 400;
        text-decoration: underline;
        transition: color 0.3s ease;

        &:hover {
          color: var(--custom-yellow);
        }
      `;
    case 'link_15':
      return css`
        font-size: 15px;
        font-weight: 400;
        text-decoration: underline;
        transition: color 0.3s ease;

        &:hover {
          color: var(--custom-yellow);
        }
      `;
    case 'caption1':
      return css`
        font-size: 14px;
        font-weight: 400;
      `;
    case 'caption2':
      return css`
        font-size: 13px;
        font-weight: 400;
      `;
    case 'caption3':
      return css`
        font-size: 12px;
        font-weight: 400;
      `;
    case 'caption4':
      return css`
        font-size: 11px;
        font-weight: 500;
      `;
    case 'caption5':
      return css`
        font-size: 12px;
        font-weight: 700;
      `;
    default:
      return css``;
  }
}

export const Typography = styled(
  ({ variant = 'h1', children, ...props }: TypographyProps) => {
    const tag = (() => {
      if (HEADING_VARIANTS.includes(variant)) {
        if (variant === 'h0') return 'h1';
        return variant;
      }
      if (P_VARIANTS.includes(variant)) {
        return 'p';
      }
      return 'div';
    })();

    return createElement(tag, props, children);
  }
)`
  ${({ variant = 'h1' }) => getVariant(variant)}
`;
