import { gql } from '@apollo/client';

export const SIGN_IN = gql`
  mutation SignIn($email: String!, $password: String!) {
    signIn(input: { email: $email, password: $password }) {
      status
      lockExpiresAt
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($password: String!, $resetPasswordToken: String!) {
    updatePassword(
      input: { password: $password, resetPasswordToken: $resetPasswordToken }
    ) {
      status
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation SendResetPassword($email: String!) {
    sendResetPassword(input: { email: $email }) {
      status
    }
  }
`;

export const SIGN_OUT = gql`
  mutation SignOut {
    signOut {
      status
    }
  }
`;
