'use client';

import Link from 'next/link';
import { Suspense } from 'react';

import { CrossButton } from '@/components/Headers/SecondaryHeader/CrossButton';
import { SecondaryHeaderProps } from '@/components/Headers/SecondaryHeader/SecondaryHeader.types';
import { LogoIcon } from '@/components/ui/Icons/Icons';
import { ROUTES } from '@/constants/common';

import * as S from './SecondaryHeader.styles';

export const SecondaryHeader: React.FC<SecondaryHeaderProps> = ({
  withCloseBtn = false
}) => {
  return (
    <S.Header $withCloseBtn={withCloseBtn}>
      <Link href={ROUTES.HOME}>
        <LogoIcon />
      </Link>
      {withCloseBtn && (
        <Suspense>
          <CrossButton />
        </Suspense>
      )}
    </S.Header>
  );
};
