import { useEffect, useState } from 'react';
import { useMediaQuery as useBaseMediaQuery } from 'usehooks-ts';

import { BREAKPOINTS } from '@/constants/breakpoints';

export const useMediaQuery = (query: keyof typeof BREAKPOINTS) => {
  const mediaQuery = `(max-width: ${BREAKPOINTS[query]})`;

  const [isClient, setIsClient] = useState(false);

  const matches = useBaseMediaQuery(mediaQuery);

  useEffect(() => {
    setIsClient(true);
  }, []);

  // Return false during SSR, real value after mounting
  return isClient ? matches : false;
};
