'use client';

import styled from 'styled-components';

import { BREAKPOINTS } from '@/constants/breakpoints';
import { flexCenterAligned } from '@/styles/common';

export const Header = styled.div<{ $withCloseBtn: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ $withCloseBtn }) =>
    $withCloseBtn ? 'space-between' : 'flex-start'};
  padding: 40px 40px 20px 40px;

  @media (max-width: ${BREAKPOINTS.mobile}) {
    padding: 20px 20px 0;
  }
`;

export const CrossButton = styled.button`
  ${flexCenterAligned}
  position: absolute;
  right: 28px;
  width: 44px;
  height: 44px;
  background: none;
  border: none;
  border-radius: 50%;
  color: var(--textAndLabels-tertiary);
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: var(--bg-tertiary);
    color: var(--system-yellow);
  }

  @media (max-width: ${BREAKPOINTS.mobile}) {
    right: 6px;
  }
`;
