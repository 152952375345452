import styled from 'styled-components';

import { SnackbarType } from '@/components/ui/Snackbar/Snackbar.types';
import { Typography } from '@/components/ui/Typography/Typography.styles';
import { BREAKPOINTS } from '@/constants/breakpoints';
import { openingEffect } from '@/styles/common';

export const SnackbarWrapper = styled.div<{
  $type: SnackbarType;
  $isOpen: boolean;
}>`
  position: fixed;
  top: env(safe-area-inset-top, 0);
  left: 0;
  width: 100%;
  background-color: ${({ $type }) =>
    $type === 'error' ? 'var(--system-red)' : 'var(--system-green)'};
  padding: 15px 50px;
  z-index: 4;
  text-align: center;

  @media (max-width: ${BREAKPOINTS.mobile}) {
    padding: 15px 20px;
  }
  ${openingEffect(0)}
`;

export const Title = styled(Typography)`
  color: var(--bg-main);
`;
