import { ApolloError } from '@apollo/client';

import { STATUS_CODES } from '@/constants/common';
import { useSnackbar } from '@/providers/SnackbarProvider';

const { LIMIT_EXCEEDED, AUTHENTICATION_ERROR } = STATUS_CODES;

interface HandleGraphQLErrorsOptions {
  error: ApolloError;
  customMessage?: string;
  onLockout?: (lockoutEnd: number) => void;
  statusMessages?: Record<string, string>;
}

export const useGraphQLErrorProcessor = () => {
  const { openSnackbar } = useSnackbar();

  return ({
    error,
    onLockout,
    customMessage,
    statusMessages = {}
  }: HandleGraphQLErrorsOptions) => {
    if (!error.graphQLErrors.length) return;

    for (const { extensions, message } of error.graphQLErrors) {
      const { code, lockExpiresAt } = extensions || {};

      // Get the custom message if provided, otherwise fallback to default translation
      const errorMessage =
        statusMessages[code as string] || customMessage || message;

      switch (code) {
        case LIMIT_EXCEEDED:
          if (lockExpiresAt && onLockout)
            return onLockout(lockExpiresAt as number);
          openSnackbar(errorMessage, 'error');
          break;

        case AUTHENTICATION_ERROR:
          throw new Error(errorMessage);

        default:
          openSnackbar(errorMessage, 'error');
          break;
      }
    }
  };
};
