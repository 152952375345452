import styled, { css } from 'styled-components';

import { flexCenterAligned } from '@/styles/common';

import { ButtonSize, ButtonVariant } from './Button.type';

const primaryStyles = css`
  background-color: var(--textAndLabels-main);
  color: var(--bg-main);

  &:hover {
    background-color: var(--primary-hover);
  }
`;

const secondaryStyles = css`
  background-color: var(--bg-secondary);
  color: var(--textAndLabels-main);

  &:hover {
    background-color: var(--system-lightBlue);
  }
`;

const outlinedStyles = css`
  background-color: var(--bg-main);
  border: 1px solid var(--border-main);
  color: var(--textAndLabels-main);
  padding: 9px 19px;

  &:hover {
    background-color: var(--bg-secondary);
  }
`;

const modalPrimaryStyles = css`
  background-color: var(--system-blue);
  color: var(--promote-button-text);

  &:hover {
    background-color: var(--system-deepBlue);
  }
`;

const modalSecondaryStyles = css`
  background-color: transparent;
  border: 1px solid var(--delete-button-outline);
  color: var(--delete-button-outline);

  &:hover {
    background-color: var(--delete-button-hover);
  }
`;

const errorStyles = css`
  background-color: #ffeef0;
  color: #ff586d;

  &:hover {
    background-color: #ffdee2;
  }
`;

const largeStyles = css`
  height: 54px;
  font-size: 15px;
`;

const mediumStyles = css`
  height: 44px;
  font-size: 15px;
`;

const smallStyles = css`
  height: 40px;
  font-size: 14px;
`;

const isLoadingStyles = css`
  background-color: var(--textAndLabels-tertiary);
  color: transparent;
  cursor: wait;

  &::before {
    content: '';
    border: 2px solid #f1f1f1;
    border-top-color: #8d8f97;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    position: absolute;
    animation: spin 1s linear infinite;
  }

  &:hover {
    background-color: var(--textAndLabels-tertiary);
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

function getVariant(variant?: ButtonVariant) {
  switch (variant) {
    case 'primary':
      return primaryStyles;
    case 'secondary':
      return secondaryStyles;
    case 'outlined':
      return outlinedStyles;
    case 'modalPrimary':
      return modalPrimaryStyles;
    case 'modalSecondary':
      return modalSecondaryStyles;
    case 'error':
      return errorStyles;
    default:
      return css``;
  }
}

function getSize(size?: ButtonSize) {
  switch (true) {
    case size === 's': {
      return smallStyles;
    }
    case size === 'm': {
      return mediumStyles;
    }
    case size === 'l': {
      return largeStyles;
    }
    default:
      return css``;
  }
}

export const ButtonBox = styled.button<{
  $variant?: ButtonVariant;
  $size?: ButtonSize;
  $isLoading?: boolean;
  $disabled?: boolean;
  $fullWidth?: boolean;
}>`
  ${flexCenterAligned}
  border: none;
  border-radius: 28px;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 11px 20px;
  gap: 7px;

  &::before {
    content: none;
  }

  ${({ $variant }) => getVariant($variant)}

  &:disabled {
    background-color: var(--textAndLabels-tertiary);
    cursor: not-allowed;
  }

  ${({ $size }) => getSize($size)}
  ${({ $isLoading }) => $isLoading && isLoadingStyles}
  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `}
`;
