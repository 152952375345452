'use client';

import dynamic from 'next/dynamic';
import { useRouter, useSearchParams } from 'next/navigation';

import { ROUTES } from '@/constants/common';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { decodeFromBase64 } from '@/utils/helpers/base64';

import * as S from './SecondaryHeader.styles';

const CrossIcon = dynamic(
  () => import('@/components/ui/Icons/Icons').then(mod => mod.CrossIcon),
  { ssr: false }
);

const { HOME } = ROUTES;

export const CrossButton = () => {
  const { push } = useRouter();
  const searchParams = useSearchParams();
  const isMobile = useMediaQuery('mobile');

  const returnTo = searchParams.get('returnTo') || '';

  const handleGoBack = () => {
    const prevRoute = decodeFromBase64(returnTo);
    if (prevRoute) {
      return push(prevRoute);
    }
    push(HOME);
  };

  return (
    <S.CrossButton data-cy="back-button" onClick={handleGoBack}>
      <CrossIcon size={isMobile ? 15 : 20} />
    </S.CrossButton>
  );
};
