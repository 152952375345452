'use client';

import { createContext, useContext, useRef } from 'react';

import { Snackbar } from '@/components/ui/Snackbar/Snackbar';
import {
  SnackbarRef,
  SnackbarType
} from '@/components/ui/Snackbar/Snackbar.types';

interface SnackbarContextProps {
  openSnackbar: (message: string, type: SnackbarType) => void;
}

const SnackbarContext = createContext<SnackbarContextProps | undefined>(
  undefined
);

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within SnackbarProvider');
  }
  return context;
};

export const SnackbarProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const snackbarRef = useRef<SnackbarRef>(null);

  const openSnackbar = (message: string, type: SnackbarType) => {
    snackbarRef.current?.open(message, type);
  };

  return (
    <SnackbarContext.Provider value={{ openSnackbar }}>
      {children}
      <Snackbar ref={snackbarRef} />
    </SnackbarContext.Provider>
  );
};
