import styled from 'styled-components';

import { flexColumn, openingEffect } from '@/styles/common';

export const Wrapper = styled.div<{
  $isOpen: boolean;
  $isAlignedStart: boolean;
}>`
  position: relative;
  width: fit-content;
  ${flexColumn}
  align-items: ${({ $isAlignedStart }) =>
    $isAlignedStart ? 'flex-start' : 'center'};
  color: ${({ $isOpen }) =>
    $isOpen ? 'var(--switcher-open)' : 'var(--textAndLabels-tertiary)'};
  transition: color 0.3s ease;
`;

export const SelectedLanguage = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: 16px;
`;

export const DropdownMenu = styled.div<{ $isOpen: boolean }>`
  z-index: 1;
  position: absolute;
  top: -172px;
  ${flexColumn}
  width: 240px;
  padding: 10px 5px;
  align-items: flex-start;
  border-radius: 28px;
  background: var(--bg-main);
  box-shadow: 0 17px 34px 0 rgba(151, 151, 151, 0.08);
  ${openingEffect(10)}
`;

export const DropdownItem = styled.div<{
  $isSelected: boolean;
}>`
  padding: 13px 15px;
  cursor: pointer;
  border-radius: 28px;
  width: 100%;
  color: var(--textAndLabels-main);
  background: ${({ $isSelected }) =>
    $isSelected ? 'var(--bg-secondary)' : 'var(--bg-main)'};
  text-align: start;

  &:hover {
    color: ${({ $isSelected }) => !$isSelected && 'var(--custom-yellow)'};
  }
  transition: color 0.2s ease;
`;

export const Arrow = styled.div<{ $isOpen: boolean }>`
  transform: ${({ $isOpen }) => ($isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;
`;
