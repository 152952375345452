import { useRouter } from 'next/navigation';
import { useTranslation } from 'react-i18next';

import { LocalesEnum } from '@/constants/locales';
import { getLocaleUrl } from '@/utils/helpers/locales';

export const useLanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const router = useRouter();

  const currentLanguage = i18n.language;

  const handleLanguageChange = (newLocale: LocalesEnum) => {
    const { protocol, host, pathname, search } = window.location;
    const newUrl = getLocaleUrl({
      protocol,
      host,
      pathname,
      search,
      newLocale
    });

    router.push(newUrl);
  };

  return { currentLanguage, handleLanguageChange };
};
