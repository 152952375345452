import { PropsWithChildren } from 'react';

import { Typography } from '@/components/ui/Typography/Typography.styles';

import { ButtonBox } from './Button.styles';
import { ButtonProps } from './Button.type';

export const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
  children,
  type,
  variant = 'primary',
  size = 'l',
  isLoading,
  fullWidth = false,
  startIcon,
  ...props
}) => {
  return (
    <ButtonBox
      {...props}
      type={type ?? 'button'}
      $size={size}
      $isLoading={isLoading}
      $variant={variant}
      $fullWidth={fullWidth}
    >
      {startIcon}
      <Typography variant="title4">{children}</Typography>
    </ButtonBox>
  );
};
