'use client';

import React, { useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import { LANGUAGES } from '@/components/LanguageSwitcher/LanguageSwitcher.constants';
import { DropdownArrowIcon, GlobeIcon } from '@/components/ui/Icons/Icons';
import { Typography } from '@/components/ui/Typography/Typography.styles';
import { LocalesEnum } from '@/constants/locales';
import { useLanguageSwitcher } from '@/hooks/useLanguageSwitcher';

import * as S from './LanguageSwitcher.styles';

interface LanguageSwitcherProps {
  isAlignedStart?: boolean;
}

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
  isAlignedStart = false
}) => {
  const { currentLanguage, handleLanguageChange } = useLanguageSwitcher();
  const [isOpen, setIsOpen] = useState(false);
  const switcherRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(prevState => !prevState);
  };

  const handleLanguage = (code: LocalesEnum) => {
    handleLanguageChange(code);
    setIsOpen(false);
  };

  useOnClickOutside(switcherRef, () => setIsOpen(false));

  return (
    <S.Wrapper
      ref={switcherRef}
      $isAlignedStart={isAlignedStart}
      $isOpen={isOpen}
    >
      <S.DropdownMenu role="listbox" $isOpen={isOpen}>
        {LANGUAGES.map(lang => (
          <S.DropdownItem
            role="option"
            key={lang.code}
            $isSelected={lang.code === currentLanguage}
            onClick={() => handleLanguage(lang.code)}
            data-cy={`language-option-${lang.code}`}
          >
            <Typography variant="body2">{lang.label}</Typography>
          </S.DropdownItem>
        ))}
      </S.DropdownMenu>
      <S.SelectedLanguage
        data-cy="language-switcher-button"
        onClick={toggleDropdown}
      >
        <GlobeIcon />
        <Typography data-testid="language-switcher-button" variant="body3">
          {LANGUAGES.find(lang => lang.code === currentLanguage)?.label}
        </Typography>
        <S.Arrow $isOpen={isOpen}>
          <DropdownArrowIcon />
        </S.Arrow>
      </S.SelectedLanguage>
    </S.Wrapper>
  );
};
