import { defaultLocale, LocalesEnum } from '@/constants/locales';

export const getLocaleUrl = ({
  protocol,
  host,
  pathname,
  search,
  newLocale
}: {
  protocol: string;
  host: string;
  pathname: string;
  search: string;
  newLocale: LocalesEnum;
}): string => {
  const language =
    !newLocale || newLocale === defaultLocale ? '' : `${newLocale}.`;
  const hostnameWithoutLang = host.replace(/^(en\.|ru\.)/, '');

  return `${protocol}//${language}${hostnameWithoutLang}${pathname}${search}`;
};

export const hostname = (lang: string, host: string): string => {
  switch (lang) {
    case 'ru':
    case 'en':
      return `${lang}.${host}`;
    default:
      return host;
  }
};
